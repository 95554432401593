* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.bg-mfacolor{
  
  }

  .home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: justify;
  }

  .home-text-section-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: justify;
  }

  .primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 600px;
    color: #6a6a6a;
    margin: 1.5rem 0rem;
  }

 .page-banner {
    width: 100%;
    height: 50%;
 }

 .card-banner {
    justify-content: space-evenly;
    text-align: justify;
 }

 .card-padding {
    padding: 1rem;
 }

 .center-text {
  text-align: justify;
  
  margin: 1.5rem 0rem;
}

.mfahomebanner {
  width: 100%;
  height: 70vh;
}

.mfafp_chart {
  align-items:center;
}

.mfafp_card {
  border-radius: 8% !important;
}

.box-mfacolor{
  
  background-color: #072b49 !important;
}

.MFAPlogo {
  width: 150px;
  height: 150px;
  border-radius: 8% !important;
}



.carouselImage {
   height: 75vh;
}

.scrolltopButton {
  position: fixed;
    width: 100%;
    left: 90%;
    bottom: 40px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: green;
}





.carouselcta {
  background: #FFF;
  padding: 10px 15px;
  display: inline-block;
  color: #222;
  border-radius: 5px;
}

.carouselcaption {
  color: #FFF;
  text-shadow: 0 0 5px #000;
}

.carousel .arrows {
  display: flex;
  justify-content: center;
 
  gap: 50px;
  margin-top: 20px;
  user-select: none;
}

.carousel .arrows span {
  cursor: pointer;
  border: solid #FFF;
  border-color: #000;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
}

.carousel .arrows span.disabled {
  border-color: #555;
  cursor: default;
}

.carousel .arrows .left {
  transform: rotate(135deg);
}

.carousel .arrows .right {
  transform: rotate(-45deg);
}

@media (max-width: 768px) {
  .carouselImage {
    height: auto;
 }

 .mfahomebanner {
  width: 100%;
  height: auto;
}
}