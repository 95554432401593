.active{
    background-color: #5df0fe;
}

.mfalogo {
    background: white;
}

.bg-white {
    background: white;
}
.bg-divider {
    background: red;
}

nav{
    display: flex;
    justify-content: space-between;
    align-items:center;
    background-color: white;
    position: sticky;
    top: 0;
}

nav .ribbionMenu {
    display: contents;
}

nav .mobileMenu {
    display: none;
    
}

nav .title {
    font-size: 3rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    align-items: left;
    color: #008ca8; 
         /***color: #0383fa;***/
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}

nav .shorttitle {
    display: none;
    font-size: 1.35rem;
    margin: 0rem;
    padding-top: .5rem;
    padding-left: 3rem;
    padding-bottom: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #008ca8;
}

nav ul{
    display: flex;
    padding: 1.5rem 0 0 0;
    justify-content: space-evenly; 
    /* flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    flex-wrap: nowrap; */
  }
  
  nav ul li {
    list-style: none;
  }
  
  nav ul li a {
    display: block;
    text-decoration: none;
    color: black;
    background-color: #5df0fe;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
  }

 .text-decor-none{
    text-decoration: none;
    
  }
  
  nav ul li a:not(.active):hover{
    background-color: #042267;
  }

  nav div.shortmenu {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 0.2rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.5rem;
    height: 2rem;
    padding: 0 0 0 0.6rem ;    
  }

  .ribbionDescrption {
    display: flex;
  }


  nav div .shortmenu span {
    height: 0.4rem;
    width: 100%;
    background-color: #008ca8;
    border-radius: 0.2rem;
  }

  .div-padding {
    padding: 1rem;
    text-align: center;
  }

  ul .mobileMencDesp {
    display: block;
  }

  @media (max-width: 768px) {
    .mfalogo {
        display: none;
    }
    nav .ribbionMenu {
        display: none;
    }
    
    nav .mobileMenu {
        display: flex;
        
        background-color: white;
    }
    nav .title {
        display: none;
    }
    nav .shorttitle {
        display: flex;
    }

    nav div .shortmenu {
        display: flex;
    }

    ul .mobileMencDesp {
        
      display: none !important;
      }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav div ul {
        display: none; 
        flex-direction: column;
        background-color: white;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav div ul.open {
        display: flex;
        
        top: 10rem;
        left:0;
        
        background-color: 'blue';
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}

