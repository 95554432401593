.App {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}









.mfaFlipcard{
  transform-style: preserve-3d;
  transition: all 0.8s ease;
  position: relative;
}

.mfaFlipcard:hover{
  transform: rotateY(180deg);
}
 .cardFront{
  backface-visibility: hidden;
}

.cardBack{
  top:0;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}
